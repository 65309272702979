import React from "react"
import Fade from "react-reveal/Fade"
import CustomersImg from "../../images/customers.png"

const Customers = () => {
  return (
    <div className="max-w-7xl mx-auto px-6 mt-10 h-auto mb-10 flex flex-row 	">
      <div className="product-image bg-white w-full flex flex-col justify-between align-middle border-8 border-red-700  p-12 rounded-lg xxs:flex-col xs:flex-col sm:flex-col xxs:p-5 lg:p-12">
        <div className="max-w-7xl xxs:w-full xs:w-full sm:w-3/4 ">
          <Fade bottom cascade>
            <div>
              <h1 className="text-white text-7xl font-bold xxs:text-2xl xs:text-2xl sm:text-3xl lg:text-5xl xl:text-7xl">
                <span class="text-gradient palette-red">Parceiros</span>
              </h1>
            </div>
          </Fade>
        </div>
        <div className="max-w-7xl xxs:w-full xs:w-full sm:w-3/4 ">
          <div className="max-h-7xl overflow-hidden flex align-middle justify-center sm:block">
            <img
              className="rounded-xl w-2/6 h-auto"
              alt="Our customers"
              src={CustomersImg}
            ></img>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Customers

import React from "react"
import Mobber from "../images/mobber.png"
import Cialo from "../images/cialo.png"
import Adpilot from "../images/adpilot.png"

const FeaturedBlog = () => {
  return (
    <div className="max-w-7xl mx-auto px-8 mt-10">
      <h1 className="text-white font-poppins font-bold text-6xl xxs:text-lg xs:text-xl sm:text-xl lg:text-6xl">
        Produtos <br></br> digitais&nbsp;
        <span class="text-gradient palette-red">inteligentes</span>
      </h1>
      <p className="text-white text-lg mt-5 opacity-50 w-3/4 xxs:w-full lg:w-3/4">
        Nossos produtos digitais idealizados para potencializar vendas e
        auxiliar na tomada de decisão de maneira inteligente.
      </p>
      <div className="grid grid-cols-3 mt-10 gap-4 xxs:grid-cols-1 lg:grid-cols-3">
        <div className="rounded-xl overflow-hidden relative product-image">
          <img className="relative" alt="mobber" src={Mobber}></img>
          <div class="opacity-0 hover:opacity-100 duration-300 absolute inset-0 z-10 text-3xl text-white font-bold p-8">
            mobber. - A rede social para compras e descontos.
          </div>
        </div>
        <div className="rounded-xl overflow-hidden relative product-image">
          <img className="relative" alt="mobber" src={Cialo}></img>
          <div class="opacity-0 hover:opacity-100 duration-300 absolute inset-0 z-10 text-3xl text-white font-bold p-8">
            Cialo - Transformando feedbacks em insights.
          </div>
        </div>
        <div className="rounded-xl overflow-hidden relative product-image">
          <img className="relative" alt="blog" src={Adpilot}></img>
          <div class="opacity-0 hover:opacity-100 duration-300 absolute inset-0 z-10 text-3xl text-white font-bold p-8">
            adPilot. - IA para otimização do Google Ads.
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeaturedBlog

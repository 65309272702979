import React from "react"
import Fade from "react-reveal/Fade"

const FeatureSection = () => {
  return (
    <div className="max-w-7xl mx-auto ">
      <Fade bottom cascade>
        <div className="w-full flex flex-row justify-between mt-10 xxs:flex-col xs:flex-col sm:flex-row">
          <div className="rounded-xl w-full h-52 flex flex-col content-center justify-center align-middle text-center m-5 palette-red hover:from-purple hover:to-pink transition duration-500 ease-in-out">
            <h1 className="text-white text-5xl">60+</h1>
            <h3 className="text-white">mil cliques mensais</h3>
          </div>
          <div className="rounded-xl w-full h-52 flex flex-col content-center justify-center align-middle text-center m-5 palette-red hover:from-purple hover:to-pink transition duration-500 ease-in-out">
            <h1 className="text-white text-5xl">700+</h1>
            <h3 className="text-white">mil impressões</h3>
          </div>
          <div className="rounded-xl w-full h-52 flex flex-col content-center justify-center align-middle text-center m-5 palette-red hover:from-purple hover:to-pink transition duration-500 ease-in-out">
            <h1 className="text-white text-5xl">3+</h1>
            <h3 className="text-white">mil usuários diários</h3>
          </div>
          <div className="rounded-xl w-full h-52 flex flex-col content-center justify-center align-middle text-center m-5 palette-red hover:from-purple hover:to-pink transition duration-500 ease-in-out">
            <h1 className="text-white text-5xl">3</h1>
            <h3 className="text-white">produtos digitais inteligentes</h3>
          </div>
        </div>
      </Fade>
      <Fade bottom cascade>
        <div className="mt-10 px-8">
          <h1 className="text-white text-7xl font-bold xxs:text-2xl xs:text-2xl sm:text-3xl lg:text-5xl xl:text-7xl">
            Por que escolher a<br></br>
            <span class="text-gradient palette-red">Click</span>?
          </h1>
        </div>
      </Fade>
      <Fade bottom cascade>
        <div className="mt-10 flex flex-row justify-space xxs:flex-col xs:flex-col sm:flex-row">
          <div className="w-2/6 palette-red p-8 rounded-xl m-5 xxs:w-full xs:w-full sm:w-2/6">
            <h1 className="text-white text-4xl">O que fazemos</h1>
            <div className="mt-5">
              <div>
                <h2 className="text-white text-xl">Presença digital</h2>
              </div>
              <div className="mt-5">
                <h2 className="text-white text-xl">Gestão de Google Ads</h2>
              </div>
            </div>
          </div>

          <div className="w-4/6 highlights p-8 rounded-xl m-5 xxs:w-full xs:w-full sm:w-4/6">
            <h1 className="text-white text-4xl">O que nos motiva</h1>
            <p className="mt-10 text-white opacity-50">
              A nossa equipe está empenhada em impulsionar a presença online de
              negócios como o seu. Oferecemos serviços diversos que abrem portas
              para inclusão e sucesso digital. Descubra como podemos ajudá-lo a
              crescer, otimizar seus negócios e destacar num ambiente altamente
              competitivo.{" "}
            </p>
          </div>
        </div>
      </Fade>
      <Fade bottom cascade>
        <div className="mt-10 px-8">
          <h1 className="text-white text-7xl font-bold xxs:text-2xl xs:text-3xl sm:text-5xl lg:text-7xl">
            Soluções &nbsp;
            <span class="text-gradient palette-red">personalizadas</span>
          </h1>
        </div>
      </Fade>
      <Fade bottom cascade>
        <div className="mt-10 flex flex-row justify-space xxs:flex-col xs:flex-col sm:flex-row">
          <div className="w-1/2 palette-red p-8 rounded-xl m-5 xxs:w-full xs:w-full sm:w-1/2">
            <h1 className="text-white text-2xl">Presença digital</h1>
            <p className="mt-10 text-white opacity-50 text-sm">
              Se tem um negócio novo ou deseja aumentar o alcance do seu público
              alvo, estar entre os primeiros resultados nos motores de busca é o
              primeiro passo para aumentar receitas e divulgar de forma mais
              ampla o diferencial dos seus serviços. Negócios “offline”
              dificilmente conseguem captar novos clientes.Descubra como podemos
              ajudá-lo a aumentar a visibilidade da sua empresa, criar uma
              identidade visual e destravar seu negócio para potenciais
              clientes.
            </p>
          </div>

          <div className="w-1/2 highlights p-8 rounded-xl m-5 xxs:w-full xs:w-full sm:w-1/2">
            <h1 className="text-white text-2xl">Google Ads</h1>
            <p className="mt-10 text-white opacity-50 text-sm">
              Transformamos o seu investimento em publicidade digital em
              resultados concretos e mensuráveis, maximizando o retorno sobre o
              investimento (ROI) e impulsionando o crescimento do seu negócio.
              Nossa equipe é composta por profissionais certificados pelo Google
              Ads, com anos de experiência na criação, gerenciamento e
              otimização de campanhas publicitárias. Utilizamos as melhores
              práticas do mercado e as mais recentes atualizações da plataforma
              para garantir que suas campanhas estejam sempre à frente.
            </p>
          </div>
        </div>
      </Fade>
    </div>
  )
}

export default FeatureSection
